@import './variables.scss';

.App {
  position: relative;
}

.App-logo {
  height: 1em;

  @include sm-break(){
    height: 2em;
  }
}

.row.navbar {
  position: sticky;
  top: 0;
  z-index: 1020;
  background-color: $red;

  @include sm-break(){
    position: unset;
  }
}

input[type=button], input[type=submit], .mc-form-wrapper > div button { 
  background: $red;
  color: #fff;
  border-radius: 0;
  border: none;
  padding: .5em 1em;
  @include sm-break(){
    width: 162px;
  }
}
.form-label,
.form-check-label {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
}
.form-control {
  border: $main-border $red;
  border-radius: $main-border-rad;
}
.form-label {
  
}
.custom-file-input:lang(en)~.custom-file-label::after {
  content: '';
  padding: 0px;
  border: none;
}
input[type="checkbox"] {
  -webkit-appearance: none;
  border: $main-border $red;
  border-radius: 0px;
  padding: 6px;
  position: relative;

  &:checked {
    background: $red;
    

    &:after {
      content: '\E876';
      color: $white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: 'Material Icons';
    }
  }
}
.form-check-label {
  text-align: left;
  cursor: pointer;
  display: inline-grid;

  .text-link {
    text-decoration: underline;
    transition: all .5s ease;

    &:hover {
      color: $dark-grey;
    }
  }
}
.upload-wrap {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-left: .5rem;
  }
}
.file-upload {
  max-width: 285px;
  margin: 0 auto;

  &:hover {
    .custom-file-label {
      border: $main-border $red;
      transition: $main-trans;
      background: $white;
      color: $red;
    }
  }

  @include md-break {
    max-width: 340px;
  }
}
.invalid-feedback,
.invalid-tooltip {
  text-align: center;
  padding: .5rem;
  color: $white;
  background: $secondary-brand-color;
  border-radius: $main-border-rad;
  width: 100%;
  margin: .5rem 0;
}
#for-check-error.form-check {
  position: initial;
}
.form-check {
  padding: 0 1em 1em 1em;
  display: grid;
  grid-template-columns: 5px 95%;
  grid-template-rows: 5px 2em;
  column-gap: 1.5em;
  position: relative;
  .form-check-input {
    margin-left: 0;
  }
  .invalid-feedback {
    position: absolute;
    bottom: -40px;
  }
  @include md-break() {
    
    max-width: 340px;
    margin: 0 auto;
  }
}

// Buttons
.btn,
.custom-file-label {
  background: $red;
  text-align: center;
  border: $main-border transparent;
  border-radius: $main-border-rad;
  color: $white;
  font-weight: bold;
  transition: $main-trans;
  text-transform: uppercase;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
}
.btn {

  @include md-break() {
    max-width: 340px;
  }

  &:focus,
  &:visited,
  &:active {
    outline: none;
    box-shadow: none;
    border: $main-border $white;
    background: $red;
    color: $white;
  }

  &:hover {
    border: $main-border $red;
    transition: $main-trans;
    background: $white;
    color: $red;
  }

  &.btn-secondary {
    background: $white;
    color: $red;

    &:hover {
      border: $main-border $white;
      background: $red;
      color: $white;
    }
  }
  &.btn-text-link {
    display: block;
    text-align: right;
    text-decoration: underline;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 2.5rem;
    font-weight: 500;
    background: transparent;
    color: $red;
    transition: $main-trans;
    max-width: 100%;

    &.dynamicInput-add {
      max-width: inherit;
      float: right;
      width: inherit;
    }

    &:hover,
    &:active, {
      border-color: transparent;
      font-weight: 600;
      background-color: transparent;
      color: $dark-grey;
    }
  }
}
.text-link {
  text-decoration: underline;
  transition: all .5s ease;

  &:hover,
    &:active, {
      border-color: transparent;
      background-color: transparent;
      color: $dark-grey;
    }
}
.custom-file {
  height: calc(1.5em + .75rem + 3px);

  .custom-file-label,
  .custom-file-input {
    height: 100%;
  }
}
.btn-primary:not(:disabled):not(.disabled).active, 
.btn-primary:not(:disabled):not(.disabled):active, 
.show>.btn-primary.dropdown-toggle{
  border-color: transparent;
  font-weight: 600;
  background-color: transparent;
  color: $red;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, 
.btn-primary:not(:disabled):not(.disabled):active:focus, 
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-primary.disabled, 
.btn-primary:disabled {
  background-color: $btn-disabled;
  border-color: $btn-disabled;

  &:hover {
    color: $white;
  }
}

.section-title-wrap {
  background: $red;
  color: $white;

  h4 {
    font-weight: 24px;
  }
}

.custom-file-input {
  margin-bottom: 2em;
  border: $main-border $red;
  cursor: pointer;
}

.form-submit {
  margin: 2em 0 4em;
}

.btn-secondary {
  border-radius: 20px;
  font-weight: 600;
}

.header {
  background-color: $red;
  position: relative;

  h2 {
    color: $white;
    text-transform: uppercase;
    font-size: 1.5em;
    margin: 0px;
  }
} 

.header-logo {
  width: 100%;
  padding: 0.5rem 0rem;
  transform: scale(1.5);

  @include lg-break {
    transform: scale(.9);
  }
}

#star1 {
  text-align: left;
}
#star2 {
  text-align: right;
}

.additional-awards {
  background: $red;
  color: $white;
  padding-bottom: 1.5rem;
}

.header-text-wrap {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  color: $white;
  background-image: url(./assets/oval.svg);
  background-position: bottom;
  min-height: 200px;
  display: flex;
  justify-content: center;
  margin-top: -2px;

  &.categories,
  &.faq {
    padding-bottom: 2rem;
  }
  &.faq {
    flex-direction: column;
  }
  .container {
    align-self: center;
    display: grid;
    grid-template-rows: 1fr, 2fr, 1fr;
  }
  .section-title {
    font-size: 18px;
  }
  .row {
    padding: .25em 1em;
  }
  .header-text {
    font-size: 18px;
    width: 75%;
    margin: 0 auto;
  }

  @include md-break() {
    margin-bottom: 3rem;
    
    .header-text {
      max-width: 300px;
      margin: 0 auto;
      padding: 2em 0;
    }
  }
  @include lg-break() {
    background-position: center bottom;
  }
}

.sparkle-wrap {
  position: relative;
}
#star1 {
  position: absolute;
  left: 0;
  top: -5px;
}
#star2 {
  position: absolute;
  right: 0;
  bottom: 25px;
}

.navbar {
  display: grid;
  grid-template-columns: repeat(3, 33%);
  padding: .5rem 0;

  .navbar-brand {
    margin-right: 0px;
  }

  @include md-break() {
    padding: 1em;    
  }
}

.navbar-light .navbar-toggler {
  padding: 0px;
  justify-self: start;
  color: $white;
  border: none;

  .MuiSvgIcon-root {
    height: 1.5em;
    width: 1.5em;
  }

  &.navbar-expand {
    display: inline-block;
  }

  &:not(.collapsed):after {
    display: block;
    position: fixed;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // background: #ccc;
    z-index: 1;
    cursor: default;
  }

}


.brand-logo {
  justify-self: end;
  margin-right: 0;
}

.trophy {
  text-align: center;
  margin: -35px auto 2rem auto;
  z-index: 5;
  position: relative; 
  width: 35%;

  #trophy {
    width: 100%;
  }
}
#red-star-left {
  position: absolute;
  left: -40%;
  top: 15%;
}
#red-star-right {
  position: absolute;
  bottom: 10%;
  right: -45%;
}


//Submit
.category-list {
  color: $white;
  width: 100%;
  padding: 0 0 4em;
  text-align: center;
}

.btn-dropdown {
  border: $main-border $white;
  padding: .5rem;  
  
  @include md-break() {
    width: 340px;
  }
}

#dropdown-wrap {
  position: relative;
  width: 100%;

  @include md-break() {
    width: 340px;
  }
  
  &.show-wrap {

    .btn-dropdown {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      background: $white;
      color: $red;
      border: $main-border $red;
      border-bottom: none;
      padding: .5rem;
    }
  }
  svg {
    float: right;
  }
}

.navbar-nav,
.dropdown-content {
  border-radius: $smaller-border-rad;
  border: $main-border #ea1d25;
  background: white;
}
.dropdown-content {
  display: none;
  position: absolute;
  background: $white;
  overflow: auto;
  width: 100%;
  text-align: center;
  z-index: 5;
  color: $red;

  &.show {
    border-radius: 0px 0px 15px 15px;
    border: $main-border $red;
    border-top: none;
  }

  .entry-category {
    display: block;
    border-bottom: 1px solid $grey;

    &:nth-child(1) {
      border-top: 1px solid $grey;
    }
    &:hover {
      color: $red;
      text-decoration: underline;
      transition: $main-trans;
    }

  }
}
.phone-wrap {
  display: grid;
  grid-template-columns: 15% 83%;
  column-gap: 2%;

  .invalid-feedback {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}
.area-code {
  color: $red;
  text-align: center;
}
.terms {
  max-width: 340px;
  margin: 0 auto;
  
  .form-label {
    margin-left: 1rem;
    a {
      cursor: pointer;
      text-decoration: underline;
      transition: $main-trans;
      &:hover {
        text-decoration: underline;
        color: $grey-70;
      }
    }
  }

  @include md-break() {
    justify-content: center;
  }
}
.file-limit,
.uploaded-name {
  text-align: center;
  display: block;
}
.file-limit {
  font-size: 12px;
  font-weight: 400;
  margin: 0.5rem 0;
}
.uploaded-name {
  font-size: 16px;
  margin: 1rem 0;
}
.entry-category,
.navbar-light .navbar-nav .nav-link {
  padding: 1.5em;
  text-decoration: none;
  display: block;
  text-transform: uppercase;
  transition: $main-trans;
  font-weight: bold; 
  margin: 0 2em;
  color: $red;

}

.navbar-light .navbar-nav .nav-link {
  border-bottom: 1px solid $grey;

  &:last-child {
    border-bottom-color: transparent;
  }
}

#responsive-navbar-nav {
  position: absolute;
  z-index: 5;
  top: 80%;
  left: 0;
  width: 100%;
  transition: all .5s ease;
  color: purple;

  @include md-break() {
    top: 65%;
    left: 1rem;
    width: 35%;
  }

  .navbar-nav {
    &:after {
      width: 0;
      height: 0;
      border-left: 7px solid $red;
      border-right: 7px solid $red;
      border-bottom: 9px solid $white;
      position: absolute;
      content: '';
      top: -5px;
      left: 13px;
    }
  }
  
}

// Categories
.category-item,
.section-title {
  text-align: center;
}

.section-title {
  padding: 2rem 0;
  font-weight: bold;
  font-size: 28px;

  &.secondary {
    font-size: 20px;
    text-transform: uppercase;
  }
  &.splash {
    text-transform: uppercase;
    font-size: 13px;
    max-width: 230px;
    margin: 0 auto;
    padding: 1rem 0; 
    @include md-break() {
      font-size: 18px;
      max-width: 100%;
    }
  }
}

.category-item {
  padding: 2rem 0;
  cursor: pointer;
  &.inactive {
    opacity: .6;
    cursor: default;
    > div {
      // pointer-events: none;
    }
  }
}

.category-title {
  text-transform: capitalize;
  margin-top: 1em;
  font-size: 24px;
}
.icon-wrap {
  position: relative;

  img {
    transform: scale(.9);
  }
}
.awards {
  .icon-bg {
    position: absolute;
    background: $red;
    width: 90%;
    height: 77%;
    top: 0;
    z-index: -1; 

    @include lg-break() {
      width: 80%;
    }
    @include xl-break() {
      width: 75%;
    }

    &.dog {
      right: 0;
      border-bottom-left-radius: 100px;
      border-top-left-radius: 100px;
    }
    &.cat {
      left: 0;
      border-bottom-right-radius: 100px;
      border-top-right-radius: 100px;
    }
  }
}

//FAQ
.search-wrap {

  .section-title {
    text-transform: uppercase;
    color: $white;
  }
}
.search {
  position: relative;

  button {
    position: absolute;
    right: 0;
    top: 50%;
    padding: 0 .75rem;
    height: auto;
    width: auto;
    transform: translateY(-50%);
    background: transparent;
    color: $red;

    &:active,
    &:focus,
    &:visited,
    &:hover {
      outline: none;
      border-color: transparent;
      background-color: transparent;
    }
    &.d-lg-block {
      border-width: 4px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  .form-control {
    height: auto;
    padding: .5rem 2.5rem .5rem .5rem;
    text-align: right;
  }
  @include md-break {
    margin: 0 auto;
    width: 395px;
  }
}
#noSearchResults {
  display: none;
}
.card {
  border: none;
}
.card-header {
  border-radius: 0;
  background-color: transparent;
  border-bottom: 1px solid $grey-70;
  color: $darkest-grey;
  text-transform: capitalize;
  padding: 1.25em 0em;
  position: relative;

  &:after {
    content: '\e5c5';
    font-family: 'Material Icons';
    position: absolute;
    right: 0;
    height: 18px;
    width: 18px;
  }

  &.active:after {
    content: '\e5c7';
    font-family: 'Material Icons';
    position: absolute;
    right: 0;
    height: 18px;
    width: 18px;
  }
}

.card-body {
  color: $dark-grey;
  padding: 1.25rem 0;
  font-weight: 400;
  font-size: 14px;
}
.accordion-title {
  font-weight: 600;
}
.dynamicInput {
  position: relative;
}
.dynamicInput-item {
  width: 99%;
}
.dynamicInput-button,
.dynamicInput-button:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.dynamicInput-button {
  border: none; 
  background: none;
  right: -5px;
  width: 15px;

  &:after {
    content: '\e15b';
    font-family: 'Material Icons';
    font-size: 22px;
    color:  $red;
  }

  &:disabled::after {
    color: $grey;
  }
}

//Partners
.partners {

  @include md-break() {
    display: grid;
    grid-template-columns: 1fr 2fr;
    justify-content: center;
    column-gap: 20%;
  }
}
.host p,
.partner-wrap p {
  font-size: 16px;
  text-align: center;
  margin: 0px auto 1rem auto;
}
.partner-img-wrap {
  margin: 2rem auto;
  width: 50%;

  img {
    width: 100%;
  }
}
.partner-wrap {
  @include md-break() {
    display: flex;
    flex-direction: column;

    .section-title,
    p {
      text-align: left;
    }
    .partner-img-wrap {
      width: 100%;
    }
  }
}  
.host {
  text-align: center;
  margin: 1rem 0;

  img {
    width: 100%;
    max-width: 316px;
  }

  @include md-break() {
    margin: 2rem 0;
    text-align: left;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    align-items: center;

    .section-title {
      text-align: left;
    }
    p {
      text-align: left;
    }
  }
}
.section-title.partner {
  padding: 1rem auto;

  @include md-break() {
    padding: 2rem auto;
  }
}
//Countdown
.countdown {
  margin: 0 auto;
}
.countdown-wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  .countdown-col {
    border-right: 2px solid rgb(211, 211, 211);
    font-weight: bold;
    font-size: 36px;
    position: relative;
    margin-bottom: 2.5rem;
    line-height: 1;

      &:last-child {
        border-right: transparent;
      }
      @include sm-break() {
        font-size: 48px;
      }
      @include md-break() {
        font-size: 53px;
      }
      span {
        display: block;
          margin: 0 .5rem;
      }
  } 
}
.days:after,
.hours:after,
.minutes:after,
.seconds:after {
  position: absolute;
  color: white;
  font-size: 25px;
  bottom: -40px;
  left: 0;
  right: 0;
}
.days:after {
  content: 'D';
}
.hours:after {
  content: 'H';
}
.minutes:after {
  content: 'M';
}
.seconds:after {
  content: 'S';
}

//Footer
.footer {
  background: $red;
  color: $white;
  padding: 1.5rem 0;
  margin-top: -2px;
  @include md-break() {
    padding: .5rem 0;
  } 

  .social-link {
    margin: .5rem;

    img {
      width: 40px;
      
      @include md-break() {
        width: 50px;
        transform: scale(.75);
      } 
    }
  }

  @include md-break() {
    margin-top: 3rem;

    .social-link {
      margin: 0px;
    }
  }
}
.footer-brand-logo {
  transform: scale(.75);
}
.footer-upper {
  font-size: 16px;
  text-align: center;
  display: block;

  @include md-break() {
    display: flex;
    justify-content: space-around;
    padding: 2rem 0;

    .footer-social {
      display: flex;
      padding: 0.5rem 0;
      a {
        margin: 0 .5rem;
      }
    }
  }
  .footer-social {
    padding: 3rem 0;
  }
  div {
    padding: .5rem 0;

    &:first-child {

      @include md-break() {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  ul {
    padding: 0;
    margin: 4em 0em 2em;

    @include md-break() {
      margin: 0px;  
      display: inline-flex;    
    }
  }
  li {
    display: inline;
    padding:  0 .25em;
  }
}
.ellipsis-footer {
  position: relative;
  bottom: -5px;
}
.content-rules-wrapper {
  background: $white;
}

.footer-title {
  color: $white;
  text-transform: uppercase;
  font-weight: lighter;
  font-size: 1em;
  margin-bottom: 2em;
}
.legal-wrapper {
  background: $white;
  padding: 2rem 1rem;

  @include md-break {
    padding: 2.5rem 2rem;
  }
}
.legal {
  color: $black;
  font-weight: lighter;
  text-align: left;
  font-size: 16px;
  margin-bottom: 0;
}
.legal-link {
  color: $red;
  text-decoration: underline;
}
.full-legal {
  font-size: 18px;
  color: $black;

  h2 {
    font-size: 22px;
  }
  h3 {
    font-size: 20px;
    font-weight: 400;
  }
  p {
    margin: 1rem 0 2rem;
    font-weight: 300;
  } 
}

.condensed {
  .full-legal {
    font-size: 10px;
    .section-title {
      font-size: 1.5em;
    }
    h3 {
      font-size: 1.2em;
    }
  }
}


// Popover
#popover {
  height: inherit;
  max-width: 90%;
  border: $main-border $red;  
  border-radius: 2rem;
  color: $red;
  text-align: center;
  font-weight: 600;
  top: -55px !important;
  @include md-break() {
    max-width: 390px;
  }
  
  .arrow {
    // top: calc(-1.5rem - 1px);
    &:before {
      border-bottom-color: $red;
      // border-width: 1.5rem;
      // top: -27px;
    }

    &:after {
      top: 3px;
      // border-width: 1.5rem;
      // left: 0px;  
    }
  }
  h3 {
    color: $red;
    text-transform: capitalize;
    font-weight: bold;
  }
  button {
    width: 100%;
  }
}
.selected ~ .col-md-4 {
  opacity: .5;
}
.selected > div {
  pointer-events: none;
}
.popover-body {
  padding: 2em 1em;

  p {
    color: $red;
  }
}

.mobileOnly,
.show {
  display: block;
}

.noMobile {
  display: none;
}

// Splash 
.splash-logo {
  width: 100%;
}
.splash-wrapper {
  color: $white;
  z-index: 10;
  text-align: center;
  position: absolute;
  min-height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;

  .register {
    color: $white;
    font-size: 16px;

    &.date {
      @include md-break() {
        font-size: 25px;
      }
    }
  }

  > .splash {
    min-height: 100vh;
    a.inline-link {
      color: #fff;
      text-decoration: underline;
      &:hover {
        color: rgb(211, 211, 211);
      }
    }
  }
}
.splash {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  height: 100%;
  background: $red;

  .presented {
    font-size: 14px;
    text-transform: uppercase;
    padding: 0;

    @include md-break() {
      font-size: 20px;
      font-weight: 400;
      margin-right: 3rem;
    }
  }
  .presented-wrap {
    display: block;

    @include md-break() {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.splash-info {
  align-self: center;

  .countdown-col {
    font-size: 40px;
    @include md-break() {
      font-size: 57px;
    }
  }

  .days:after,
  .hours:after,
  .minutes:after,
  .seconds:after {
    font-size: 19px;
    bottom: -30px;
    @include md-break() {
      font-size: 27px;
      bottom: -40px;
    }
  }
}



.modal {
  text-align: center;
}
.modal-content {
  border: none;
}

.legal-modal {

  .modal-body,
  .legal-wrapper,
  .modal-content {
    border-radius: $main-border-rad;
  }
  .modal-body {
    background: $red;
    padding: 1em 2em;
    border-radius: $main-border-rad;
    
    p {
      color: $dark-grey;
      font-weight: 500;
      font-size: 14px;
    }
    .legal-wrapper {
      background: $white;
      padding: 0px;
    }
    .legal {
      height: 500px;
      overflow: auto;
    }
  }
  .modal-content {
    background: $red;
  }
  .modal-header {
    color: $white;
    border: none;
    text-transform: capitalize;
    display: flex;
    align-items: baseline;
    position: relative;
    align-content: flex-start;
  }
  .modal-title {
    text-align: center;
    width: 100%;
  }
  .close {
    order: -1;
    margin: 0;
    span {
      font-size: 3rem;
      text-shadow: none;
      color: white;
      font-weight: 500;
    }
  }

} // end .legal-modal


.loading-logo {
  width: 75%;

  @include lg-break() {
    width: 65%;
  }
}
.presented {
  text-transform: capitalize;
  font-weight: lighter;
  padding: 2em 0;

  &.form {
    text-align: center;
  }
  img {
    width: 35%;
  }
}

.presented-wrap {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.form-brand-title {
  display: block;
  font-weight: 500;
  text-transform: uppercase;
}

//Submit Success
.success-wrap {
  background: $red;
  margin: 2em auto;
  text-align: center;
  border-radius: $main-border-rad;
  padding: 2em;

  @include md-break() {
    max-width: 770px;
  }
  
  h1, 
  p {
    color: $white;
  }

  h1 {
    font-size: 33px;
    text-transform: capitalize;
  }

  p {
    padding: 1.5em;
  }
}

// Media Queries

@include md-break()  {
  .awards-web,
  .footer-title {
    display: inline;
  }

  .footer-upper,
  .footer-info {
    align-items: center;
  }
  .footer-info {
    display: flex; 

      img {
        transform: scale(.5);
        transform-origin: center;
      }
  }

  .social-icon {
    transform: scale(.75);
  }

  .dropdown {
    max-width: 35%;
  }

  .content-rules-wrapper {
    text-align: left;
  }

  .sparkle-wrap {
    margin: 0 auto;
  }
  .sparkle-wrap,
  .countdown {
    width: 60%;
  }

}

@include lg-break() {
  .sparkle-wrap,
  .countdown {
    width: 40%;
  }
  .splash-info {
    .countdown {
      width: 460px;
    }
  }
}



