@import './variables.scss';

body {
  margin: 0;
  font-family: $fontfamily;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $white;
  color: $red;
}

a {
  color: $red;
}
p {
  font-size: 1.2em;
  line-height: 1.2em;
  color: $dark-grey;
}
p.large {
  font-size: 1.8em;
}
h1, h2, h3, h4, h5, h6 {
  font-family: $fontfamily;
  font-weight: 400;
}
h2 {
  text-transform: uppercase;
}
.highlight {
  color: $red;
}
.text-indent {
  display:inline-block;
  margin-left:1em;
}
.bold {
  font-weight: bold;
}