$black: #000000;
$grey: #D6D6D6;
$grey-70: #707070;
$dark-grey: #4A4A4A;
$darkest-grey: #3B3B3B;
$lightest-grey: #DEDEDE;
$btn-disabled: #B5B5B5;
$white: #ffffff;
$red: #ea1d25;
$secondary-brand-color: rgba(234, 29, 37, 0.75);
$fontfamily: "Gotham A", "Gotham B", sans-serif;

$main-border: 3px solid;
$large-border: 4px solid;
$main-border-rad: 25px;
$smaller-border-rad: 15px;
$smaller-btn-width: 340px;
$border-rad-50: 50%;
$main-trans: all .5s ease;

@mixin sm-break() {
    @media (min-width: 576px) {
        @content;
    }
}

@mixin md-break() {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin lg-break() {
    @media (min-width: 992px) {
        @content;
    }
}

@mixin xl-break() {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin xxl-break() {
    @media (min-width: 1400px) {
        @content;
    }
}